import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import "./ProjectList.scss";
import RenderContent from "../../RenderContent";
import Pagination from "../../Pagination";
import { Image } from "../../Image";
import { ProjectListItem } from "../../Blog/ProjectListItem";

export const ProjectPage = (props) => {
  const { posts, title, pageContext, categories, pathPrefix, siteMetadata } =
    props;

  return (
    <section className="project-list">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="list">
              {posts.map(
                (slide, index) =>
                  !slide.node.slug.includes("gatsby") && (
                    <ProjectListItem
                      key={index}
                      data={slide.node}
                      siteMetadata={siteMetadata}
                      pathPrefix={pathPrefix}
                    />
                  )
              )}
            </div>
          </div>
          <div>
            <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectPage;

ProjectPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_projects {
    id
    project_category
    # author {
    #   name
    #   path
    #   avatar_urls {
    #     wordpress_48
    #   }
    # }
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    path
    wordpress_id
    acf {
      layout: layout_projects_projects {
        __typename
        ...ProjectDetailsQuery
      }
    }
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 670, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
